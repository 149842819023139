<template>
  <div class="content-i">
    <div class="content-box">
      <div class="row">
        <!-- Profile Detail -->
        <div class="col-sm-5">
          <profile-detail-component></profile-detail-component>
        </div>

        <!-- Profile Setting -->
        <div class="col-sm-7">
          <setting-Component></setting-Component>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProfileDetailComponent from './ProfileDetailComponent.vue';
import SettingComponent from './SettingComponent.vue';
import TeamMemberComponent from './TeamMemberComponent.vue';

export default {
  components: {
    ProfileDetailComponent,
    SettingComponent,
    TeamMemberComponent,
  },
};
</script>
